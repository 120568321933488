// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

@import 'variables';

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
    font-family: 'Open Sans', sans-serif;

    a, p, span, li {
        font-family: 'Open Sans', sans-serif;
    }
}

hr {
    -moz-border-bottom-colors: none;
    -moz-border-image: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #EEEEEE -moz-use-text-color #FFFFFF;
    border-style: solid none;
    border-width: 1px 0;
    margin: 18px 0;
}

.btn-remove-img {
    position: absolute;
    top: 5px;
    left: 20px;
}

.logo {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
}


.navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: baseline;
}

.navbar .navbar-collapse {
    text-align: center;
}

.navbar-default {
    border-color: black;
    border-top-width: 2px;
    border-bottom-width: 2px;
}

.navbar-nav > li > a, .navbar-brand {
    padding-top: 6px !important;
    padding-bottom: 0 !important;
    height: 28px;
}


.navbar {
    min-height: 28px !important;
    margin-bottom: 0;
}

.selected {
    color: black !Important;
    font-weight: bold;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: black;
}

.idiomas {
    float: none;
    padding-right: 15px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.banderas {
    width: 20px;
    margin-right: 10px;
    margin-top: 10px;
}

.jumbo {
    background-color: white;
    padding-top: 30px;
    padding-bottom: 30px;
}

.titulo {
    padding-left: 15px;
    font-family: 'Abril Fatface';
    padding-bottom: 20px;
}

.titulo2 {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    padding-left: 15px;
    padding-bottom: 30px;
    padding-top: 20px;
    font-size: 2.2em;
}

.tituloYeguas {
    padding-left: 0px;
    font-family: 'Abril Fatface';
    padding-bottom: 20px;
}

p, label, .entradaTexto, li {
    font-family: 'Open Sans', sans-serif;
    font-stretch: normal;
    font-size: 18px;
}

.avisoLegal {
    font-family: 'Open Sans', sans-serif;
    font-stretch: normal;
    font-size: 0.8em;
}


.navegacion {
    font-family: 'Open Sans', sans-serif;
    font-stretch: normal;
}

#ano {
    padding-top: 10px;
    padding-right: 25px;
    padding-bottom: 10px;
}

.footer {
    border-color: black;
    border-style: solid;
    border-top-width: 2px;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
}

.navbar-text {
    margin-top: 0px;
    margin-bottom: 0px;
}

.carousel-indicators li {
    border-radius: 0px !Important;
    width: 15px;
    height: 15px;
}

.carousel-indicators .active {
    background-color: white;
    width: 17px;
    height: 17px;
    margin: 0;
}

.noticias {
    padding-bottom: 30px;
}

.separador {
    background-color: black;
    color: black;
    border: solid 1px black;
    width: 100%;
}

.navbar-brand {
    color: black !important;
}

@media (max-width: 767px) {
    .navbar-brand {
        display: block !Important;
        margin-top: 12px;
    }
}

@media (min-width: 768px) {
    .navbar-brand {
        display: none !Important;
    }
}

@media (max-width: 767px) {
    #logoCabecera {
        display: none !Important;
    }
}

@media (min-width: 768px) {
    #logoCabecera {
        display: block !Important;
    }
}


/*HERRADURA MENU*/
.navbar-toggle .iconoNuevo {
    display: inline;
    width: 30px;
    border-radius: 1px;
    padding-bottom: 4px;
}

navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: white;
}

.navbar-default .navbar-toggle {
    border-color: black;
}

.navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    padding-top: 6px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}


#textoMenu {
    display: inline;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 4px;
    margin-top: 4px;
}

/*FIN HERRADURA MENU*/

/*FORMULARIO*/

.form-horizontal {
    margin-top: 25px;
}


.form-horizontal .control-label {
    text-align: left;
}

/*FIN FORMULARIO*/

/*NUESTRAS YEGUAS*/
.yegua {
    padding-bottom: 25px;
}

.nombreCaballo {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    color: #CF6837;
}

.nombreCaballo:hover {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    color: #A1512B;
}


/*PROGRAMA DE CRIA*/
.list-group-item-heading {
    padding-top: 10px;
}

.textoHiper:link {
    text-decoration: none;
}

.textoHiper {
    color: black;
}

a:link {
    text-decoration: none;
}

.parrafoSeparacion {
    padding-bottom: 25px;
}


/*POTROS*/

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {

    vertical-align: middle;

}

table {
    border-top: 1px hidden;
    border-bottom: 1px hidden;
}

table tr {
    border-left: 1px hidden;
    border-right: 1px hidden;
}

table td {
    border: 1px solid black;
}


.hembra {
    color: #CF6837;
    margin-bottom: 5px;
}

.tituloNoticias, #marcasLG {
    /*color: #CF9537;*/
    color: #CF6837;
}

a {
    color: #098F82;
}

a:hover {
    color: #077066;
}

.bloque {
    background-color: #f8f8f6;
}


hr {
    margin-bottom: 0px;

}


.imgCaballos {
    margin-bottom: 8px;
}

.color {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #CF6837;

}


/*FALTA TEXT AREA*/
textarea:focus,
textarea[type="text"]:focus,
textarea[type="textarea"]:focus,
input[type="textarea"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input:focus,
.uneditable-input:focus {
    border-color: rgba(9, 143, 130, 1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(9, 143, 130, 1);
    outline: 0 none;
}


/*NOTICIAS*/

.list-group-item-noBorder {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    margin-bottom: 0px;
    background-color: #fff;
    border: 0px !Important;
    text-decoration: none !Important;
    color: dimgray;
    font-family: 'Open Sans', sans-serif;
    font-stretch: normal;
    font-size: 1.2em;
}

.list-group {
    border: none;
    box-shadow: none;
}


@media (max-width: 767px) {

    #marcasVertical {
        display: none;
    }
}

@media (min-width: 767px) {

    #marcasHorizontal {
        display: none;
    }
}


#marcasVertical {
    margin-top: 28px;
}

#botonMarcas {
    font-family: 'Open Sans', sans-serif;
    font-stretch: normal;
    font-size: 1.2em;
    color: #CF6837;
}

.inlineBrown {
    font-family: 'Open Sans', sans-serif !Important;
    font-stretch: normal !Important;
    font-size: 1.2em !Important;
    color: #CF6837 !Important;
    display: inline;
}

.inline {
    display: inline;
    color: black;

}


/*ESTILOS CARRUSEL MINIATURAS*/

#miniaturas {
    /*Ancho de la caja, se modifica para encajar todas las miniaturas: tamaño miniatura * nMiniaturas + (8 * nMiniaturas) */
    /*width:1554px; */
    width: 948px;

    height: 150px;
    z-index: 0;
    position: relative;
}

.scroll {
    width: 100%;
    height: 160px;
    margin-bottom: 15px;
    overflow-y: scroll;
    position: relative;

    z-index: 0;
}

.thumb img, thumb .nuevo {
    /*border:1px solid #000;*/
    margin-top: 8px;
    margin-right: 8px;
    float: left;
    z-index: -1;

    width: 150px;
    height: 80px;

    /*
    height: 150px;
    width: 251px;
    */

}

.thumb span {
    position: relative; /*absolute;*/
    display: none;
}


.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/*FORMULARIO*/

.contact-form {
    padding-top: 25px;
}


.botonesAdmin {
    padding: 15px;
    padding-top: 25px;
}

.primero {
    margin-top: 20px;
}


/*ADMIN*/
.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-signin .form-signin-heading, .form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.account-wall {
    margin-top: 20px;
    padding: 40px 0px 20px 0px;
    background-color: #f7f7f7;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.login-title {
    color: #555;
    font-size: 18px;
    font-weight: 400;
    display: block;
}

.profile-img {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.need-help {
    margin-top: 10px;
}

.new-account {
    display: block;
    margin-top: 10px;
}

.botonLogin {
    margin-top: 10px;
}


.imgTabla {
    width: 100%;
}

.tdTabla {
    width: 400px;
}

.fotosDiv {
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    text-align: center;
    border-top: 1px solid #e5e5e5;
}

.botonBorrarImagenNoticia {
    margin-top: 25px;
    margin-bottom: 20px;
    float: right;
}

.botonEditar {
    margin: 5px;
    float: right;
}


.botonGuardarComentarios {
    margin-bottom: 105px;
}


.btn-go-down {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 45px;

    &:focus, &:active:focus, &.active:focus {
        outline: 0 none;
        background-color: white;
    }
}

.btn-go-up {
    position: fixed;
    bottom: 50px;
    right: 10px;
    width: 45px;

    &:focus, &:active:focus, &.active:focus {
        outline: 0 none;
        background-color: white;
    }
}


.my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mab-1 {
    margin-bottom: 1rem;
}

.mab-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}


.mx-1 {
    margin-right: 1rem;
    margin-left: 1rem;
}

.mx-2 {
    margin-right: 2rem;
    margin-left: 2rem;
}

.mx-3 {
    margin-right: 3rem;
    margin-left: 3rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

